import cn from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { useQRCode } from "next-qrcode"
import { useSettings } from "src/layout/page/settingsContext"
import { getTranslation } from "src/lib/translations"
import { DownLoadDetails } from "src/sanity/types"
import { isMobile, stringToRichText } from "src/utils"
import ButtonGroup from "../button-group/ButtonGroup"
import s from "./SendSms.module.scss"
import { createAppLinkUrl, createDeferredDownloadLink } from "src/lib/oneclick/util"
import { getGaIds, trackGTMEvent } from "src/lib/tagmanager"
import { useOneclickTracking } from "src/lib/oneclick"
import countryCodes from "./countrycodes"
import { getDefaultCountryCode } from "./CountryCodeSelect"
import AppStore from "../app-store/AppStore"
import InputField from "../input-field/InputField"
import { getCallToActionTrackingHandler } from "../call-to-action/CallToAction"
import { useExperiments } from "src/layout/page/experimentsContext"
import { IntercomUserCustomAttributes } from "src/lib/intercom"
import Icon, { IconType } from "../icon/Icon"
import { LinkHandler } from "../link-handler/LinkHandler"
import { Button } from "../button/Button"
import { Link } from "../link/Link"
import Steps from "src/modules/steps-module/Steps"
import Accordion from "../accordion/Accordion"
import Panel from "../accordion/Panel"
import RichText from "../rich-text/RichText"
// import SelectSearch from "react-select-search"

export default function BusinessSignup({ downloadDetails, signupType = "download", showOnlyform = false, initialValues, className }: { downloadDetails: DownLoadDetails, signupType?: "download" | "contact-me", initialValues?: { email?: string, phone?: string[], cvr?: string }, showOnlyform?: boolean, className?: string }) {
    const [stage, setStage] = useState<"createLead" | "companyInformation" | "companyIndustry" | "companyIndustryRequiresExtraDocumentation" | "cvrHelp" | "download" | "contactMeReceipt" | "unsupportedSwedishCompany" | "fortnox">("createLead")
    const { lang, segment, faq } = useSettings()
    const t = getTranslation("downloadOverlay", lang)
    const { ids, source, campaign, parsedSource } = useOneclickTracking()
    const [qrLink, setQrLink] = useState<string>("")
    const [mobileLink, setMobileLink] = useState<string>("")
    const [phone, setPhone] = useState(["", ""])
    const [email, setEmail] = useState("")
    const [cvr, setCVR] = useState("")
    const [name, setName] = useState("")
    const [customAttributes, setCustomAttributesRaw] = useState<Partial<IntercomUserCustomAttributes>>({})
    const setCustomAttributes = (newAttributes: Partial<IntercomUserCustomAttributes>) => {
        setCustomAttributesRaw({ ...customAttributes, ...newAttributes })
    }
    const [isNewOrEstablishedBusiness, setIsNewOrEstablishedBusiness] = useState<false | "new" | "established">(null)
    const [intercomLeadId, setIntercomLeadId] = useState<string>("")
    const isIntercomLeadIdSet = useRef(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isStage1FormValid, setIsStage1FormValid] = useState(false)
    const [didSubmitStage2, setDidSubmitStage2] = useState(false)

    const isMoonriseSite = downloadDetails?._id === "e2471831-2669-4057-991e-a7c79f8cf9cf"
    // web_business_onboarding experiment
    const { activeExperiments } = useExperiments()
    // const experiment = activeExperiments["web_business_onboarding"]
    // const forceWebOnboarding = typeof window !== "undefined" && window.location.pathname === "/dk/erhverv/ref-dinero" && "web"
    // const webBusinessOnboardingVariant = !isMoonriseSite && (forceWebOnboarding || experiment?.activeVariant) as "web" | "app"

    // b2b_onboarding experiment
    // const b2bOnboardingExperiment = activeExperiments["b2b_onboarding_choice"]
    // const onboardingVariant = !isMoonriseSite ? b2bOnboardingExperiment?.activeVariant : false

    // b2b_cvr_help experiment
    const cvrHelpExperiment = activeExperiments["b2b_cvr_help"]

    // lead_qualify experiment
    const leadQualifyExperiment = activeExperiments["lead_qualify"]

    useEffect(() => {
        if (initialValues?.email && !email)
            setEmail(initialValues.email)
        if (initialValues?.phone && !phone[1])
            setPhone(initialValues.phone)
        if (initialValues?.cvr && !cvr)
            setCVR(initialValues.cvr)
    }, [initialValues])

    useEffect(() => {
        (async () => {
            const gaIds = await getGaIds()
            setQrLink(createDeferredDownloadLink({ ids: { ...ids, ...gaIds }, linkSource: "qr", segment, lang, source, campaign }))
        })()
    }, [stage])


    // Setup to ensure stage 2 has the intercom lead Id from stage 1 before submitting form
    useEffect(() => {
        if (intercomLeadId && isIntercomLeadIdSet?.current) {
            isIntercomLeadIdSet.current(intercomLeadId)
            isIntercomLeadIdSet.current = null
        }
    }, [intercomLeadId])
    const waitForIntercomLeadId = () => {
        return new Promise<string>((resolve) => {
            if (intercomLeadId) {
                resolve(intercomLeadId)
            } else {
                isIntercomLeadIdSet.current = resolve
            }
        })
    }

    useEffect(() => {
        if (stage === "download" && isMobile) {
            (async () => {
                const gaIds = await getGaIds()
                const mobileLink = createAppLinkUrl({
                    ids: { ...ids, ...gaIds },
                    source,
                    campaign,
                    ...(downloadDetails?.onelink ? { onelinkUrl: downloadDetails?.onelink } : {}),
                })
                setMobileLink(mobileLink)

                // if (isMobile)
                // location.href = mobileLink
            })()
        }
    }, [stage])

    // Form validation
    useEffect(() => {
        const isPhoneValid = phone[1].length > 3
        const isEmailValid = email.match(/^[^@]+@[^@]+\.[^@]{2,}$/) !== null
        const isDKCvrValid = (cvr === "0" || cvr.length === 8)

        if (lang === "se") {
            if (signupType === "download")
                setIsStage1FormValid(isPhoneValid && isEmailValid && !!isNewOrEstablishedBusiness)
            if (signupType === "contact-me")
                setIsStage1FormValid(isPhoneValid)
        }
        else if ((lang === "dk" || lang === "en") && !isMoonriseSite) {
            if (signupType === "download") {
                setIsStage1FormValid(isPhoneValid && isEmailValid && isDKCvrValid)
            } else {
                setIsStage1FormValid(isPhoneValid && isDKCvrValid)
            }
        } else
            setIsStage1FormValid(isPhoneValid || isEmailValid)
    }, [phone, email, cvr, isNewOrEstablishedBusiness, lang, signupType, isMoonriseSite])

    useEffect(() => {
        // Pick up lead details from query params, to prefill the form
        const urlParams = new URLSearchParams(window.location.search)
        const phoneParam = urlParams.get("phone")
        const emailParam = urlParams.get("email")
        const cvrParam = urlParams.get("cvr")
        const couponParam = urlParams.get("coupon")
        const intercomLeadId = urlParams.get("intercomLeadId")

        if (phoneParam) {
            const countryCode = phoneParam.includes("+") ? phoneParam.slice(0, 3) : null
            setPhone([countryCode, phoneParam.slice(3)])
        }
        if (emailParam) {
            setEmail(emailParam)
        }
        if (cvrParam) {
            setCVR(cvrParam)
        }
        if (intercomLeadId && intercomLeadId !== "undefined") {
            setIntercomLeadId(intercomLeadId)
        }
        if (couponParam) {
            document.cookie = `BUSINESS_ONBOARDING_COUPON=${couponParam}; domain=.lunar.app; path=/; SameSite=Strict; Secure`
        }

    }, [])

    const Canvas: any = useQRCode().Canvas

    async function getRecaptchaToken() {
        return new Promise((resolve) => {
            window.grecaptcha.ready(async function () {
                window.grecaptcha.execute("6LfdMsUhAAAAAGicH2UnCNbICPSMUtPBDyb6M0pI", { action: "submit" }).then(async function (recaptchaClientToken) {
                    resolve(recaptchaClientToken)
                })
            })
        })
    }

    const title = signupType === "download" ? downloadDetails.title : downloadDetails.contactTitle
    const subtitle = signupType === "download" ? downloadDetails.subtitle : downloadDetails.contactSubtitle
    const ctaText = signupType === "download" ? downloadDetails.ctaText : downloadDetails.contactCtaText
    const timeInCopenhagen = new Date().toLocaleString("en-US", { timeZone: "Europe/Copenhagen", hour: "numeric", hour12: false })
    const hourInCopenhagen = parseInt(timeInCopenhagen.split(":")[0])
    const isWeekDay = new Date().getDay() >= 1 && new Date().getDay() <= 5
    const isSalesTeamAvailable = isWeekDay && hourInCopenhagen >= 9 && hourInCopenhagen <= 15 && hourInCopenhagen !== 12
    // const isSalesTeamAvailable = false // Temporarily disabled

    return (
        <div className={className}>
            <div className="stage">
                {stage === "createLead" && (
                    <>
                        {title && (<h3 className="heading-100 mb-small">{title}</h3>)}
                        {subtitle && <div className="paragraph-100 mb-medium">{subtitle}</div>}
                        {signupType === "contact-me" && downloadDetails.showInboundPhoneNumber && isSalesTeamAvailable && (
                            <>
                                <div className="mb-small" style={{ verticalAlign: "middle" }}>
                                    <a href="tel:+4570716879"><button className={cn("button button--pill")} onMouseDown={getCallToActionTrackingHandler({ linkType: "external", title: "📞 70 71 68 79", type: "link" })}>📞 70 71 68 79</button></a>
                                    <small className="live-pulse" style={{ fontSize: "0.8em", verticalAlign: "middle" }}>{lang === "dk" ? "Vi sidder klar nu" : "We're online now"}</small>
                                </div>
                                <LinkHandler link={faq.link} className="paragraph-100 text-gray-100 mb-medium" style={{ fontStyle: "italic" }}>
                                    {lang === "dk" ? "Leder du efter kundesupport? Klik her" : "Are you looking for customer support? Click here"}
                                </LinkHandler>
                                <hr style={{ borderBottom: "1px solid var(--gray-60)", width: "100%" }} className={"mb-large"} />
                                <div className="paragraph-100 mb-medium">{lang === "dk" ? "Eller udfyld formularen, og bliv ringet op." : "Or fill out the form and get a call back."}</div>
                            </>
                        )}
                        <form
                            action=""
                            // noValidate
                            onSubmit={async (e) => {
                                e.preventDefault()

                                // Require one of the two
                                if (!isStage1FormValid || isLoading) {
                                    return
                                }
                                setIsLoading(true)

                                // if ((lang === "dk" || lang === "en") && webBusinessOnboardingVariant !== "web")
                                if (lang === "dk" || lang === "en") {
                                    if (isMoonriseSite || signupType === "contact-me") {
                                        setStage("contactMeReceipt")
                                    } else if (signupType === "download") {
                                        if (cvrHelpExperiment?.activeVariant === "cvrhelp" && cvr === "0")
                                            setStage("cvrHelp")
                                        else
                                            setStage("download")
                                    }
                                }
                                if (lang === "se")
                                    setStage(signupType === "download" ? "companyInformation" : "contactMeReceipt")
                                if (lang === "no")
                                    setStage("download")

                                trackGTMEvent("signup_create_lead", {
                                    install_lead_type: signupType === "contact-me" ? "contact-me" : "signup-form",
                                    gclid: ids.gclid,
                                    cvr,
                                })
                                if (cvr.length >= 7 && signupType === "download")
                                    trackGTMEvent("signup_cvr", {
                                        cvr,
                                    })

                                if (phone[1].length > 3)
                                    trackGTMEvent("send-link-by-sms", {
                                        sms_phone_number_country_code: phone[0].replaceAll("+", ""),
                                        sms_phone_number_country: countryCodes.find(cc => cc.phoneCode === phone[0])?.name.en || phone[0].replaceAll("+", ""),
                                        sms_phone_number: phone[1],
                                        sms_did_change_country_code: getDefaultCountryCode(lang).value !== phone[0],
                                        gclid: ids.gclid,
                                        install_lead_type: "skip_install_lead", // This skips the install_lead event, as we trigger that through the "signup_create_lead" event
                                    })

                                // Store email, phone and CVR in cookies at top level domain to use with web onboarding
                                // if (signupType === "download" && webBusinessOnboardingVariant === "web") {
                                //     document.cookie = `BUSINESS_ONBOARDING_EMAIL=${email}; domain=.lunar.app; path=/; SameSite=Strict; Secure`
                                //     document.cookie = `BUSINESS_ONBOARDING_PHONE=${phone.join("")}; domain=.lunar.app; path=/; SameSite=Strict; Secure`
                                //     document.cookie = `BUSINESS_ONBOARDING_ORGANISATION_NUMBER=${cvr}; domain=.lunar.app; path=/; SameSite=Strict; Secure`
                                // }

                                const gaIds = await getGaIds()
                                const recaptchaClientToken = await getRecaptchaToken()

                                // Decide on lead source
                                const referrer = location.href.split("?")[0].split("#")[0]
                                let leadSource = signupType === "download" ? "web-business-signup" : "web-business-contact-me"
                                if (referrer.includes("flyt-erhvervskonto") || referrer.includes("slip-for-skjulte-gebyrer") || campaign?.toLowerCase()?.includes("switching"))
                                    leadSource = "web-business-switching"
                                if (isMoonriseSite)
                                    leadSource = "web-banking-services"

                                // Partner pages
                                if (location.pathname.includes("/ref-")) {
                                    const partners = ["dinero", "dinero-100", "billy", "flatpay", "pensopay", "dandomain"]
                                    const partner = location.pathname.split("/ref-")[1].split("/")[0]
                                    if (partners.includes(partner))
                                        leadSource = signupType === "download" ? `partner-${partner}-get-started` : `partner-${partner}-contact-me`
                                }

                                // Decide on actions
                                let actions = ["createLead", "sendSms"]
                                if (lang === "se") {
                                    if (signupType === "contact-me") {
                                        actions = ["createLead", "sendToPipedrive"]
                                    }
                                    else {
                                        if (leadQualifyExperiment?.activeVariant === "qualify") {
                                            actions = ["createLead"]
                                        } else {
                                            actions = ["createLead", "sendSms"]
                                        }

                                    }
                                }
                                if (["dk", "en"].includes(lang) && signupType === "contact-me")
                                    actions = ["createLead"]
                                if (isMoonriseSite)
                                    actions = ["createLead", "sendToPipedrive"]

                                const leadExperiments = []
                                // if (webBusinessOnboardingVariant)
                                //     leadExperiments.push(`web_business_onboarding=${webBusinessOnboardingVariant}`)
                                // if (onboardingVariant)
                                //     leadExperiments.push(`b2b_onboarding_choice=${onboardingVariant}`)
                                if (cvrHelpExperiment?.activeVariant && cvr === "0")
                                    leadExperiments.push(`b2b_cvr_help=${cvrHelpExperiment?.activeVariant}`)
                                if (leadQualifyExperiment?.activeVariant)
                                    leadExperiments.push(`lead_qualify=${leadQualifyExperiment?.activeVariant}`)

                                const body = {
                                    actions,
                                    email,
                                    phone: phone.join(""),
                                    cvr,
                                    lang,
                                    segment: "business",
                                    referrer,
                                    ...(name ? { name } : {}),
                                    ids: { ...ids, ...gaIds },
                                    ...(intercomLeadId ? { intercomLeadId } : {}),
                                    ...(downloadDetails?.onelink ? { onelinkPath: new URL(downloadDetails.onelink).pathname.slice(1) } : {}),
                                    customAttributes: {
                                        ...customAttributes,
                                        ...(leadExperiments.length > 0 ? { "Website lead experiments": leadExperiments.join(",") } : {}),
                                    } as IntercomUserCustomAttributes,
                                    utm_source: parsedSource,
                                    utm_campaign: campaign,
                                    marketingConsent: true,
                                    leadSource,
                                    recaptchaClientToken,
                                    deviceType: isMobile ? "mobile" : "desktop",
                                }

                                const createLeadRes = await fetch(`/api/createLead`, {
                                    body: JSON.stringify(body),
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                })
                                const resBody = await createLeadRes.json()
                                if (resBody.intercomLeadId) {
                                    setIntercomLeadId(resBody.intercomLeadId)
                                }

                                // if (webBusinessOnboardingVariant === "web") {
                                //     location.href = "https://signup.lunar.app/business"
                                //     setTimeout(() => {
                                //         setIsLoading(false)
                                //     }, 1000)
                                // }
                            }}
                        >
                            {/* If is banking services page */}
                            {isMoonriseSite && (
                                <>
                                    <InputField
                                        type="text"
                                        name="personName"
                                        label={"Full name"}
                                        placeholder={"Enter your name"}
                                        onChange={(value) => setName(value)}
                                        className="mb-large"
                                        required
                                    />
                                    <InputField
                                        type="text"
                                        name="companyName"
                                        label={"Company name"}
                                        placeholder={"Enter your company name"}
                                        onChange={(value) => setCustomAttributes({ "company_name": value })}
                                        className="mb-large"
                                        required
                                    />
                                    <InputField
                                        type="email"
                                        name="email"
                                        label={t.emailLabel}
                                        placeholder={"Enter company email"}
                                        onChange={(value) => setEmail(value)}
                                        className="mb-large"
                                        required
                                    />
                                    <InputField
                                        type="tel"
                                        name="phone"
                                        label={t.phoneNumberLabel}
                                        placeholder={"01234 567890"}
                                        setPhone={setPhone}
                                        className="mb-large"
                                        phoneValue={phone}
                                        required
                                    />
                                    <InputField
                                        type="text"
                                        name="personName"
                                        label={"Full name"}
                                        placeholder={"Tell us how we can help or support your goals"}
                                        onChange={(value) => setCustomAttributes({ lead_note: "Tell us how we can help or support your goals?: " + value })}
                                        className="mb-large"
                                        required
                                    />
                                </>
                            )}
                            {!isMoonriseSite && (
                                <>
                                    <InputField
                                        type="tel"
                                        name="phone"
                                        phoneValue={phone}
                                        label={t.phoneNumberLabel}
                                        placeholder={t.phoneNumberPlaceholder}
                                        setPhone={setPhone}
                                        className="mb-large"
                                        required
                                    />

                                    {/* Show email if Download form, and not contact */}
                                    {signupType === "download" && (
                                        <InputField
                                            type="email"
                                            name="email"
                                            label={t.emailLabel}
                                            placeholder={t.emailPlaceholderBusiness}
                                            value={email}
                                            onChange={(value) => setEmail(value)}
                                            className="mb-large"
                                            required
                                        />
                                    )}

                                    {/* In Denmark we show CVR field, in Sweden we ask if they have an established biz */}
                                    {["dk", "en"].includes(lang) && ["download", "contact-me"].includes(signupType) && (
                                        <>
                                            <InputField
                                                type={lang === "se" ? "text" : "number"}
                                                name="cvr"
                                                value={cvr === "0" ? "" : cvr}
                                                label={t.organisationNumberLabel}
                                                placeholder={t.organisationNumberPlaceholder}
                                                onChange={(value) => setCVR(value.replace(/\D/g, ""))}
                                                className="mb-small"
                                                required={cvr !== "0"}
                                            />
                                            {/* <SelectSearch
                                                id="cvr-search"
                                                value={cvr}
                                                search
                                                fuzzySearch={false}
                                                getOptions={async (searchValue) => {
                                                    console.log({ searchValue })
                                                    const res = await fetch(`/api/script?searchCvr&q=${encodeURIComponent(searchValue)}`)
                                                    const json = await res.json()
                                                    console.log("Search res", json)
                                                    return json
                                                }} // When searching, show all
                                                options={[]}
                                                debounce={100}


                                                onChange={(val, newCVR) => { setCVR((newCVR as unknown as string)) }}
                                                // renderOption={(props, option, snapshot, className) => {
                                                //     return (
                                                //         <button {...props as any} className={className}>
                                                //             <span>{option.emoji}</span>
                                                //             <span style={{ margin: "0px 20px", minWidth: "60px", display: "inline-block" }}>{option.phoneCode}</span>
                                                //             <span>{option.countryName}</span>
                                                //         </button>
                                                //     )
                                                // }}
                                                // renderValue={(valueProps, snapshot, className) => (
                                                //     <input {...valueProps as any} tabIndex={-1} required={required} onKeyPress={(e) => { if (e.key === "Enter") { e.preventDefault(); return } if ((snapshot.highlighted as unknown as number) < 0) { valueProps.onKeyDown(new KeyboardEvent("ArrowDown", { key: "ArrowDown" })) } }} className={cn(className, s["send-sms__input__pre-fix"])} />
                                                // )}

                                                onBlur={() => void 0}
                                                onFocus={() => void 0}
                                            /> */}
                                            <div style={{ display: "flex", justifyContent: "flex-start" }} className={cn("mb-large")}>
                                                <input
                                                    type="checkbox"
                                                    name="doesNotHaveCVRNumber"
                                                    id="doesNotHaveCVRNumber"
                                                    onChange={(e) => { e.target.checked ? setCVR("0") : setCVR("") }}
                                                />
                                                <label className={cn(s["send-sms__input__label"])} style={{ fontSize: "14px" }} htmlFor="doesNotHaveCVRNumber">{t.iDontHaveACVRNumberYet}</label>
                                            </div>
                                        </>
                                    )}

                                    {lang === "se" && signupType === "download" && (
                                        <>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }} className={cn("mb-large")}>
                                                <input
                                                    className={cn(s["send-sms__input__radio"])}
                                                    type="radio"
                                                    name="newOrEstablished"
                                                    id="newBusiness"
                                                    onChange={(e) => { if (e.target.checked) { setIsNewOrEstablishedBusiness("new"); setCVR("0") } }}
                                                />
                                                <label className={cn(s["send-sms__input__label"])} style={{ fontSize: "14px" }} htmlFor="newBusiness">Jag startar ett nytt företag</label>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }} className={cn("mb-large")}>
                                                <input
                                                    className={cn(s["send-sms__input__radio"])}
                                                    type="radio"
                                                    name="newOrEstablished"
                                                    id="establishedBusiness"
                                                    onChange={(e) => { if (e.target.checked) { setIsNewOrEstablishedBusiness("established") } }}
                                                />
                                                <label className={cn(s["send-sms__input__label"])} style={{ fontSize: "14px" }} htmlFor="establishedBusiness">Jag har ett etablerat företag</label>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            <ButtonGroup className="mb-small">
                                <Button type="submit" className={cn("button button--pill")}>
                                    {/* Show spinner when loading */}
                                    {isLoading ? <Icon type={IconType.LOADING_SPINNER} /> : ctaText}
                                </Button>
                            </ButtonGroup>
                            <div className={cn("paragraph-50")} style={{ fontSize: "12px", color: "var(--gray-100)" }}>{t.businessSignupDisclaimer}</div>
                        </form>
                    </>
                )}
                {stage === "companyInformation" && (
                    <>
                        <span className="heading-100 mb-medium">{isNewOrEstablishedBusiness === "new" ? "Välj bolagsform för den verksamhet du vill etablera" : "Vilken bolagsform har ditt företag?"}</span>
                        {/* {t.businessSignupStage2Description && <div className="paragraph-200 mb-large">{t.businessSignupStage2Description}</div>} */}
                        <form onSubmit={async (e) => {
                            e.preventDefault()

                            if (customAttributes["CVR company type"] === "Lagerbolag") {
                                setStage("fortnox")
                            } else {
                                const supportedTypes = ["Enskild firma", "Aktiebolag"]
                                if (!supportedTypes.includes(customAttributes["CVR company type"])) {
                                    setStage("unsupportedSwedishCompany")
                                } else {
                                    if (leadQualifyExperiment?.activeVariant === "qualify") {
                                        setStage("companyIndustry")
                                    } else {
                                        setStage("download")
                                    }
                                }
                            }

                            if (cvr.length >= 7)
                                trackGTMEvent("signup_cvr", {
                                    cvr,
                                })

                            setDidSubmitStage2(true)

                            const [recaptchaClientToken, assuredIntercomLeadId] = await Promise.all([
                                getRecaptchaToken(),
                                waitForIntercomLeadId(),
                            ])


                            const body = {
                                actions: ["createLead"],
                                intercomLeadId: assuredIntercomLeadId,
                                ...(cvr ? { cvr } : {}),
                                ...(Object.keys(customAttributes).length > 0 ? { customAttributes } : {}),
                                lang,
                                recaptchaClientToken,
                            }

                            await fetch(`/api/createLead`, {
                                body: JSON.stringify(body),
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },

                            })

                        }}>

                            {/* Company forms */}
                            {(isNewOrEstablishedBusiness === "new" ? ["Aktiebolag", "Lagerbolag", "Enskild firma", "Annat", "Vet inte än"] : ["Aktiebolag", "Enskild firma", "Annat"]).map((companyType) => (
                                <div style={{ display: "flex", justifyContent: "flex-start" }} key={companyType} className={cn("mb-large")}>
                                    <input
                                        className={cn(s["send-sms__input__radio"])}
                                        type="radio"
                                        name="companyForm"
                                        id={companyType}
                                        onChange={(e) => { e.target.checked && setCustomAttributes({ "CVR company type": companyType }) }}
                                        required
                                    />
                                    <label className={cn(s["send-sms__input__label"])} style={{ fontSize: "14px" }} htmlFor={companyType}>{companyType}</label>
                                </div>
                            ))}

                            {isNewOrEstablishedBusiness == "established" && (
                                <InputField
                                    type={lang === "se" ? "text" : "number"}
                                    name="cvr"
                                    value={cvr === "0" ? "" : cvr}
                                    required
                                    label={t.organisationNumberLabel}
                                    placeholder={t.organisationNumberPlaceholder}
                                    onChange={(value) => setCVR(value)}
                                    className="mb-xlarge"
                                />
                            )}
                            <button type="submit" disabled={(isNewOrEstablishedBusiness === "established" && cvr === "") || !customAttributes?.["CVR company type"] || didSubmitStage2} className={cn(s["send-sms__input__button"], "button button--pill")}>
                                {t.businessSignupStage2CtaText}
                            </button>
                        </form>
                    </>
                )}
                {stage === "companyIndustry" && (
                    <>
                        <span className="heading-100 mb-medium">Vad är din bransch?</span>
                        {/* {t.businessSignupStage2Description && <div className="paragraph-200 mb-large">{t.businessSignupStage2Description}</div>} */}
                        <form onSubmit={async (e) => {
                            e.preventDefault()

                            if (!customAttributes?.["CVR industry category"])
                                return

                            const industriesThatNeedExtraInfo = [
                                "Restaurang, kafé & bar",
                                "Handel med varor av högt värde",
                            ]
                            const leadThatNeedsExtraInfo = industriesThatNeedExtraInfo.includes(customAttributes["CVR industry category"])

                            if (leadThatNeedsExtraInfo) {
                                setStage("companyIndustryRequiresExtraDocumentation")
                            } else {
                                setStage("download")
                            }

                            const [recaptchaClientToken, assuredIntercomLeadId] = await Promise.all([
                                getRecaptchaToken(),
                                waitForIntercomLeadId(),
                            ])


                            const actions = leadThatNeedsExtraInfo ? ["createLead"] : ["createLead", "sendSms"]

                            const body = {
                                actions,
                                phone: phone.join(""), // For sendSms
                                segment: "business",
                                intercomLeadId: assuredIntercomLeadId,
                                ...(Object.keys(customAttributes).length > 0 ? { customAttributes } : {}),
                                lang,
                                recaptchaClientToken,
                            }

                            await fetch(`/api/createLead`, {
                                body: JSON.stringify(body),
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },

                            })

                        }}>
                            {[
                                "Restaurang, kafé & bar",
                                "Handel med varor av högt värde",
                                "Hantverkare & bygg",
                                "Transport & logistik",
                                "Webbutik",
                                "Information & kommunikation",
                                "Serviceyrken & frilans",
                                "Kultur, underhållning & fritid",
                                "Fastighetshandel & uthyrning",
                                "Jord- & skogsbruk",
                                "Annat",
                            ].map((industry) => (
                                <div style={{ display: "flex", justifyContent: "flex-start" }} key={industry} className={cn("mb-small")}>
                                    <input
                                        className={cn(s["send-sms__input__radio"])}
                                        type="radio"
                                        name="companyForm"
                                        id={industry}
                                        onChange={(e) => { e.target.checked && setCustomAttributes({ "CVR industry category": industry }) }}
                                        required
                                    />
                                    <label className={cn(s["send-sms__input__label"])} style={{ fontSize: "14px" }} htmlFor={industry}>{industry}</label>
                                </div>
                            ))}
                            <button type="submit" disabled={(!customAttributes?.["CVR industry category"])} className={cn(s["send-sms__input__button"], "button button--pill")}>
                                {t.businessSignupStage2CtaText}
                            </button>
                        </form>
                    </>
                )}
                {stage === "companyIndustryRequiresExtraDocumentation" && (
                    <>
                        <span className="heading-100 mb-medium">Dokumentationskrav</span>
                        <p className="mb-medium">Vi sätter höga standarder för de företag vi samarbetar med. Som en del av vår noggranna due diligence-process är det nödvändigt att tillhandahålla detaljerad dokumentation som ger oss en solid grund för att bedöma din ansökan. Nedan är en komplett lista över de dokument du måste skicka in krävs för att ansöka hos Lunar Business. <b>Var uppmärksam på att endast fullständiga ansökningar med all specificerad dokumentation kommer att beaktas.</b></p>
                        <p className="heading-50 mb-large"><b>Företagsdokumentation:</b></p>
                        <style >{`
                            .extra-documentation-accordion [class*="Panel_panel__tab"][role="tab"] {
                                padding: 1em;
                            }
                        `}</style>
                        <Accordion className="extra-documentation-accordion mb-large" initialActive={-1}>
                            {
                                [
                                    {
                                        title: "Detaljerad affärsplan",
                                        text: stringToRichText(`Sammanfattning: En exakt sammanfattning av din affärsidé.
                                    Företagsbeskrivning: En djupgående beskrivning av ditt företags mission, vision, kärnverksamheter, juridiska struktur (inklusive dokumentation därom), ägande och detaljer om produkter eller tjänster.
                                    Historik och grundare: Nyckelinformation om ditt företags etablering, viktiga milstolpar och detaljerad bakgrundsinformation om alla grundare.`),
                                    }, {
                                        title: "Omfattande marknadsanalys",
                                        text: stringToRichText(`Målmarknad och kundsegment: En exakt identifiering och detaljerad beskrivning av dina primära målgrupp(er) och kundsegment, underbyggd av relevant data.
                                    Konkurrensanalys och marknadstrender: En djupgående analys av dina konkurrenter, deras styrkor och svagheter, samt en bedömning av aktuella och förväntade marknadstrender.
                                    Omfattande marknads- och försäljningsstrategi: Detaljerade strategier för att nå din målgrupp, inklusive specifika försäljningskanaler och distributionsmetoder.`),
                                    }, {
                                        title: "Detaljerade finansiella prognoser",
                                        text: stringToRichText(`Budgetar, Intäktsprognoser och kostnadsberäkningar: Realistiska och välgrundade budgetar för kommande perioder, detaljerade intäktsprognoser underbyggda av marknadsanalysen, samt en specificerad översikt över alla förväntade kostnader.
                                    Kapitalbehov och finansieringsstrategi: En exakt beskrivning av det totala kapitalbehovet och en detaljerad plan för hur detta kapital ska anskaffas.`),
                                    }, {
                                        title: "Noggrann riskhanteringsplan",
                                        text: stringToRichText(`Identifiering av potentiella risker: En omfattande beskrivning av alla potentiella risker som kan påverka företaget negativt.
                                    Hanteringsstrategier: Detaljerade strategier och konkreta handlingsplaner för att hantera de identifierade riskerna.`),
                                    }, {
                                        title: "Kompletta anställningsavtal för alla anställda",
                                        text: stringToRichText(`Samtliga gällande anställningsavtal som tydligt anger de juridiska villkoren och bestämmelserna för varje anställd.`),
                                    }, {
                                        title: "Senaste lönespecifikation för alla anställda",
                                        text: stringToRichText(`De senaste lönespecifikationerna för alla anställda som dokumentation för aktuella lönekostnader.`),
                                    }, {
                                        title: "Detaljerade kontoutdrag",
                                        text: stringToRichText(`Kontoutdrag från ditt personliga konto för en längre period (t.ex. de senaste 6-12 månaderna) som tydligt visar saldot och transaktionshistoriken, samt dokumentation för hur dina medlen har ackumulerats över tid.`),
                                    }, {
                                        title: "Dokumentation för större insättningar",
                                        text: stringToRichText(`För alla betydande insättningar, vänligen tillhandahåll dokumentation som förklarar källorna till dessa medel. Detta kan inkludera:
                                    Arv: Bouppteckningar eller andra juridiska dokument som bekräftar arvet.
                                    Försäljning av egendom/värdeföremål: Försäljningskontrakt, lagfarter eller kvitton.
                                    Gåvor: Undertecknat gåvobrev från givaren samt dokumentation för givarens medel.
                                    Tidigare anställning/verksamhet: Årsredovisningar, lönespecifikationer eller annan relevant dokumentation för tidigare inkomster.`),
                                    },

                                ].map((i, index) => {
                                    return (
                                        <Panel
                                            id={i.title}
                                            title={i.title}
                                            key={i.title}
                                            index={index}
                                        >
                                            <RichText blocks={i.text} className="paragraph-100" />
                                        </Panel>
                                    )
                                })
                            }
                        </Accordion>

                        <ButtonGroup>
                            <Button pill onClick={() => setStage("download")}>Jag har dokumentationen ovan</Button>
                        </ButtonGroup>
                    </>
                )}

                {/* Choose onboarding experiment */}
                {/* {stage === "chooseOnboarding" && (
                    <div>
                        {(cvr === "" || cvr === "0" && ["dk", "en"].includes(lang)) && (
                            <>
                                <div className="heading-50 mb-small">{lang === "dk" ? "Få dit CVR-nummer hurtigt og gratis!" : "Get your CVR number quickly and for free!"}</div>
                                <div className="paragraph-100 mb-small">{lang === "dk" ? "For at ansøge om en erhvervskonto skal du bruge et CVR-nummer." : "To apply for a business account you need a CVR number."}</div>
                                <div className="paragraph-100 mb-small">{lang === "dk" ? "Med Lunar kan du nemt og gratis registrere din virksomhed og få et CVR-nummer på få minutter!" : "With Lunar you can easily and for free register your company and get a CVR number in minutes!"}</div>
                                <Button pill appearance="secondary" className="mb-large">{lang === "dk" ? "Start CVR-oprettelse nu" : "Start CVR registration now"}</Button>
                            </>
                        )}

                        {(cvr === "" || cvr === "0" && ["dk", "en"].includes(lang)) ? (
                            <div className="heading-50 mb-small">{lang === "dk" ? "Har du allerede et CVR-nummer?" : "Do you already have a CVR number?"}</div>
                        ) : (
                            <div className="heading-100 mb-medium">{lang === "dk" ? "Hvordan vil du ansøge?" : "How do you want to apply?"}</div>
                        )}
                        <div className="paragraph-100 mb-small">{lang === "dk" ? "Vælg, om du vil ansøge her på hjemmesiden eller downloade Lunar-appen og ansøge fra din telefon." : "Choose whether you want to apply here on the website or download the Lunar app and apply from your phone."}</div>
                        <ButtonGroup className="">
                            <Button pill appearance="secondary" onMouseDown={async function (e) {
                                const trackingFunction = getCallToActionTrackingHandler({ linkType: "external", title: "På hjemmesiden", type: "link" })
                                trackingFunction(e)
                                const [recaptchaClientToken, assuredIntercomLeadId] = await Promise.all([
                                    getRecaptchaToken(),
                                    waitForIntercomLeadId(),
                                ])
                                const body = {
                                    actions: ["createLead"],
                                    intercomLeadId: assuredIntercomLeadId,
                                    customAttributes: {
                                        platform_application_started: "web",
                                    },
                                    recaptchaClientToken,
                                }
                                const createLeadRes = await fetch(`/api/createLead`, {
                                    body: JSON.stringify(body),
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                })
                                const resBody = await createLeadRes.json()
                                console.log("Update platform application started", { resBody })


                                document.cookie = `BUSINESS_ONBOARDING_EMAIL=${email}; domain=.lunar.app; path=/; SameSite=Strict; Secure`
                                document.cookie = `BUSINESS_ONBOARDING_PHONE=${phone.join("")}; domain=.lunar.app; path=/; SameSite=Strict; Secure`
                                document.cookie = `BUSINESS_ONBOARDING_ORGANISATION_NUMBER=${cvr}; domain=.lunar.app; path=/; SameSite=Strict; Secure`

                                if (lang === "dk")
                                    location.href = "https://signup.lunar.app/da/auth/signin"
                                else
                                    location.href = "https://signup.lunar.app/business"
                            }}><Icon type={isMobile ? IconType.GLOBE : IconType.LAPTOP} style={{ marginRight: "4px" }} /> {lang === "dk" ? "På hjemmesiden" : "On the website"}</Button>

                            <Button pill appearance="secondary" onMouseDown={async function (e) {
                                const trackingFunction = getCallToActionTrackingHandler({ linkType: "external", title: "I appen", type: "link" })
                                trackingFunction(e)
                                const [recaptchaClientToken, assuredIntercomLeadId] = await Promise.all([
                                    getRecaptchaToken(),
                                    waitForIntercomLeadId(),
                                ])
                                const body = {
                                    actions: ["createLead"],
                                    intercomLeadId: assuredIntercomLeadId,
                                    customAttributes: {
                                        platform_application_started: "app",
                                    },
                                    recaptchaClientToken,
                                }
                                const createLeadRes = await fetch(`/api/createLead`, {
                                    body: JSON.stringify(body),
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                })
                                const resBody = await createLeadRes.json()
                                console.log("Update platform application started", { resBody })
                            }
                            } onClick={() => { setStage("download") }}><Icon type={IconType.SMARTPHONE} style={{ marginRight: "4px" }} /> {lang === "dk" ? "I appen" : "In the app"}</Button>
                        </ButtonGroup>
                    </div>
                )
                } */}


                {stage === "cvrHelp" && (
                    <>
                        <div className="heading-50 mb-small">{lang === "dk" ? "Sådan får du et CVR-nummer" : "How to get a CVR number"}</div>
                        <div className="paragraph-100 mb-small">{lang === "dk" ? "For at ansøge om en erhvervskonto skal du bruge et CVR-nummer." : "To apply for a business account you need a CVR number."}</div>
                        <div className="paragraph-100 mb-small">{lang === "dk" ? "Vi har en nem guide, der viser dig, hvordan du opretter en virksomhed og får et CVR-nummer." : "We have an easy-to-follow guide on how to create a company and get a CVR number."}</div>
                        <Link pill appearance="secondary" className="mb-small" href="/dk/erhverv/blog/ivaerksaetteri/sadan-opretter-du-en-virksomhed" target="_blank" onMouseDown={getCallToActionTrackingHandler({ linkType: "internal", title: "Guide til oprettelse af CVR", type: "link" })}><Icon type={IconType["TALL_BUILDING"]} style={{ marginRight: "6px", marginLeft: "-2px" }} /> {lang === "dk" ? "Guide til oprettelse af CVR" : "See CVR guide"}</Link>
                        <div className="paragraph-50 mb-large">{lang === "dk"
                            ? "Psst! Find en rabatkode i bunden af guiden – få 20% rabat hos Legal Desk, hvis du ønsker hjælp til CVR-oprettelse!"
                            : "Psst! Find a coupon code in the bottom of the guide – get 20% off at Legal Desk if you want help creating a CVR number!"}</div>

                        <div className="heading-50 mb-small">{lang === "dk" ? "Har du allerede et CVR-nummer?" : "Do you already have a CVR number?"}</div>
                        <Button pill appearance="secondary" className="mb-large" onClick={() => setStage("download")} onMouseDown={getCallToActionTrackingHandler({ linkType: "internal", title: "Fortsæt ansøgningen", type: "link" })}>{lang === "dk" ? "Fortsæt ansøgningen" : "Continue application"} <Icon type={IconType.ARROW_RIGHT} style={{ marginLeft: "4px" }} /></Button>
                    </>
                )}
                {stage === "download" && (
                    <div>
                        {/* {(onboardingVariant !== "choose") && (
                            <>
                                <div className="label-200 mb-medium">{t.businessSignupStage3SubTitle}</div>
                                <span className="heading-100 mb-xlarge">{t.businessSignupStage3Title}</span>
                            </>
                        )} */}
                        <div className="label-200 mb-small">{t.businessSignupStage3SubTitle}</div>
                        <h3 className="heading-100 mb-medium">{t.businessSignupStage3Title}</h3>
                        <div className="label-200 mb-small">{t.businessSignupStage3StepsTitle}</div>
                        <ol className="mb-large" style={{ paddingInlineStart: "20px", listStyleType: "decimal" }}>
                            {t.businessSignupStage3Steps.split("\n").map((step, i) => (
                                <>
                                    {i === 0 && (
                                        <>
                                            <li key={i} className="paragraph-200 mb-small hide-on-desktop" style={{ listStyle: "inherit" }}>{t.businessSignupStage3Step1Mobile}</li>
                                            <AppStore className="hide-on-desktop" onelinkUrl={mobileLink} orientation="horizontal" />
                                        </>
                                    )}
                                    <li key={i} className={cn("paragraph-200 mb-small", i === 0 ? "hide-on-mobile" : "")} style={{ listStyle: "inherit" }}>{step}</li>
                                </>
                            ))}
                        </ol>
                        {cvr === "" || cvr === "0" && ["dk", "en"].includes(lang) && email.length > 0 && (typeof window !== "undefined" && !location.pathname.includes("/ref-")) && (
                            <small style={{ fontStyle: "italic", margin: "30px 0", display: "block" }}>{t.businessSignupStage3CVRHint}</small>
                        )}
                    </div>
                )}
                {stage === "unsupportedSwedishCompany" && (
                    <div>
                        <p className="heading-100 mb-small"><b style={{ fontWeight: "bolder" }}>Tack för ditt intresse.</b></p>
                        <p className="mb-small">För närvarande kan vi dessvärre inte erbjuda din bolagsform ett företagskonto.</p>
                        <p className="mb-small">Du kommer få ett mail så snart vi är redo att välkomna din bolagsform. </p>
                        <p className="mb-small">Du kan redan nu ladda ner Lunar-appen, så att du är redo att ansöka så snart vi kan ta emot er.</p>
                    </div>
                )}
                {stage === "fortnox" && (
                    <>
                        <div className="label-200 mb-small">{t.businessSignupStage3SubTitle}</div>
                        <h3 className="heading-100 mb-medium">Följ stegen nedan för att komma igång 🚀</h3>
                        {/* <ol className="mb-large" style={{ paddingInlineStart: "20px", listStyleType: "decimal" }}>
                            <li style={{ listStyle: "inherit" }}>
                                Gå till Fortnox och påbörja din ansökan om lagerbolag. Använd denna länk.
                                <Link pill href="https://www.fortnox.se/lagerbolag?utm_source=lunar&utm_medium=referal" target="_blank" onMouseDown={getCallToActionTrackingHandler({ linkType: "external", title: "Registrera hos Fortnox", type: "link" })}>Registrera hos Fortnox</Link>
                            </li>
                            <li style={{ listStyle: "inherit" }}>Följ instruktionerna hos Fortnox.</li>
                            <li style={{ listStyle: "inherit" }}>Ladda ner Lunar-appen och fortsätt din ansökan genom att öppna ett företagskonto med likvidintyg.</li>
                        </ol> */}
                        <style dangerouslySetInnerHTML={{ __html: `.lead-receipt-steps { flex-direction: column; margin-top: 0 } .lead-receipt-steps li {flex-direction: row; border-color: transparent !important; padding: 8px 12px} .lead-receipt-steps li [class*="number"] { font-size: 2.5em; min-width: 15% } .lead-receipt-steps li a {margin-top: 0.875em}` }} />
                        <Steps className="lead-receipt-steps" steps={[{ text: stringToRichText("Gå till Fortnox och påbörja din ansökan om lagerbolag."), cta: { style: "button", link: { linkType: "external", id: "fortnox link", url: "https://www.fortnox.se/lagerbolag?utm_source=lunar&utm_medium=referal", title: "Registrera hos Fortnox", type: "link" } } }, { text: stringToRichText("Följ instruktionerna hos Fortnox.") }, { text: stringToRichText("Ladda ner Lunar-appen och fortsätt din ansökan genom att öppna ett företagskonto med likvidintyg.") }]} />
                    </>
                )}
                {stage === "contactMeReceipt" && (
                    <>
                        <span className="paragraph-300 mb-large">{t.businessSignupContactMeReceiptTitle}</span>
                        <span className="heading-100 mb-xlarge">{t.businessSignupContactMeReceiptSubtitle}</span>
                        {/* <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="https://cdn.sanity.io/images/inp43svr/production/f9d77a02c77c186e495e183aba7367fb1e0e1cd0-3800x3800.jpg?w=900&h=900&max-h=2000&q=80&auto=format" /> */}
                    </>
                )}
            </div >
            {!showOnlyform && (

                <div className="image-container hide-on-mobile" style={{ display: stage === "companyIndustryRequiresExtraDocumentation" ? "none" : null }}>
                    {isMoonriseSite ? (
                        <img src="https://cdn.sanity.io/images/inp43svr/production/639e1beca3f365f43a07464bd204868eb05a3e1b-1200x1200.jpg" />
                    ) : (
                        <img src={signupType === "contact-me" ? "https://cdn.sanity.io/images/inp43svr/production/669362b2f8cfb6572d5de15bd4d2553a2712f63f-578x558.png" : "https://cdn.sanity.io/images/inp43svr/production/8479f28d853774c48e0f4dbdf953e4b2bedc86c1-1722x1162.jpg?rect=500,50,1080,1080&w=900&h=900&max-h=2000&q=80&auto=format"} />
                    )}
                    {stage === "download" && (
                        <div className="hide-on-mobile" style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "2em",
                            position: "absolute",
                            left: "0",
                            right: "0",
                            top: "0",
                            bottom: "0",
                            background: "rgba(0,0,0,0.5)",
                            color: "white",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <div className="label-200 mb-small">{t.businessSignupDownloadAppTitle}</div>
                            {qrLink && (
                                <Canvas
                                    text={qrLink}
                                    options={{
                                        width: 250,
                                        errorCorrectionLevel: "L",
                                        margin: 2,
                                    }}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
        </div >
    )
}
